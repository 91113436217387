import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import useStyles from "../style";
import UserParticipant from "./UserWinner/UserParticipant";
import UserWinner from "./UserWinner/UserWinner";
import UserFCFS from "./UserWinner/UserFCFS";

import {Tabs} from 'antd';
import UserBuyer from "./UserWinner/UserBuyer";
import PublicWinnerSetting from "./UserWinner/PublicWinnerSetting";
import { exportWinner } from "../../../request/participants";

const { TabPane } = Tabs;

const UserJoinPool = (props: any) => {
  const [activeTab, setActiveTab] = useState("1");
  const classes = useStyles();
  const {
    setValue, errors, control,
    poolDetail,
  } = props;

  const dispatch = useDispatch();
  const callback = (key: any) => {
    console.log(key);
    setActiveTab(key); // Update active tab
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={callback}
        style={{
          minHeight: 500,
        }}
      >
        <TabPane tab="Participant" key="1">
          <UserParticipant poolDetail={poolDetail} />
        </TabPane>
        <TabPane tab="Winner" key="2">
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => exportWinner(poolDetail.id)}
            >
              Export Winner
            </Button>
          </div>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <PublicWinnerSetting
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
            />
          </div>
          <UserWinner poolDetail={poolDetail} callback={callback} activeTab={activeTab}/>
        </TabPane>
        <TabPane tab="FCFS Allocation" key="3">
          <UserFCFS poolDetail={poolDetail} />
        </TabPane>
        {/* <TabPane tab="Buyer" key="3">
          <UserBuyer poolDetail={poolDetail} />
        </TabPane> */}
      </Tabs>
    </>
  );
};

export default UserJoinPool;
