import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { addRPCUrl, getRPCUrlById, updateRPCUrl } from "../../request/rpc";
import { useHistory, useParams } from 'react-router-dom';
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const RPCForm = () => {
  const [url, setUrl] = useState('');
  const [network, setNetwork] = useState(' ');
  const [rpcUrl, setRpcUrl] = useState(); // Adjust the type if necessary
  const history = useHistory();
  let { id } = useParams();
  
  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };
  
  const handleNetworkChange = (event: any) => {
    setNetwork(event.target.value);
  };
  
  const fetchData = async (id: number) => {
    try {
      const response = await getRPCUrlById(id);
      if (response?.status === 200) {
        setUrl(response.data.url);
        setNetwork(response.data.network);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let response;
    try {
      if(id) {
        response = await updateRPCUrl({ url, network }, id);
      } else {
        response = await addRPCUrl({ url, network });
      }
      
      if (response?.status === 200) {
        history.push("/dashboard/rpc-url");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if(id) {
      fetchData(id);
    }
  }, [id]);
  
  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <FormControl variant="outlined" margin="normal" fullWidth>
          <InputLabel id="network-label">Network</InputLabel>
          <Select
            labelId="network-label"
            id="network"
            value={network}
            onChange={handleNetworkChange}
            label="Network"
          >
            <MenuItem value=" " disabled>Select a Network</MenuItem>
            <MenuItem value="bsc">BSC</MenuItem>
            {/* <MenuItem value="eth">ETH</MenuItem> */}
          </Select>
        </FormControl>
        <div>
          <TextField
            label="URL"
            type="text"
            id="url"
            value={url}
            onChange={handleUrlChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </div>
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => { history.push("/dashboard/rpc-url") }}>
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </DefaultLayout>
  );
}

export default RPCForm;
