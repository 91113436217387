import React, { useEffect, useState } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
// @ts-ignore
import CurrencyInput from "react-currency-input-field";
import {
  fieldMustBeGreaterThanZero,
  renderErrorCreatePool,
} from "../../../utils/validate";
import BigNumber from "bignumber.js";
import { getErc20Contract } from "../../../services/web3";
import { weiToEth } from "../../../utils";

function TotalCoinSold(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, poolDetail, control,watch } = props;
  const [totalSoldCoin, setTotalSoldCoin] = useState("");
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.total_sold_coin) {
      setTotalSoldCoin(poolDetail.total_sold_coin);
    }
  }, [poolDetail, poolDetail.token, poolDetail.network_available]);

  const handleChange = (value: any, name: any) => {
    setTotalSoldCoin(value);
    setValue("totalSoldCoin", value, { shouldValidate: true });
  };

  return (
    <>
      <br />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Total Token Amount</label>
          <CurrencyInput
            id="totalSoldCoin"
            placeholder="Please enter a number"
            value={totalSoldCoin}
            decimalsLimit={2}
            onValueChange={handleChange}
            className={`${classes.formInputBox}`}
            // disabled={isDeployed}
          />
          <input
            type="hidden"
            name="totalSoldCoin"
            value={totalSoldCoin || ""}
            ref={register({
              required: true,
              validate: {
                totalSoldCoinGreaterThanZero: fieldMustBeGreaterThanZero,
              },
            })}
          />

          <p className={classes.formErrorMessage}>
            {renderError(errors, "totalSoldCoin")}
          </p>
          <p className={classes.formErrorMessage}>
            {renderError(errors, "totalSoldCoinGreaterThanZero")}
          </p>
        </FormControl>
      </div>
    </>
  );
}

export default TotalCoinSold;
