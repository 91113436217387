import React, { useEffect, useState, useRef } from "react";
import useStyles from "../style";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Editor } from "@tinymce/tinymce-react";

// CSS in /src/index.css
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

function PoolDescription(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, poolDetail, control, header, fieldName } =
    props;
  const renderError = renderErrorCreatePool;

  const defaultValue = "";
  const [description, setDescription] = useState(defaultValue);

  useEffect(() => {
    if (poolDetail && poolDetail[fieldName]) {
      setValue(fieldName, poolDetail[fieldName]);
      setDescription(poolDetail[fieldName]);
    }
  }, [poolDetail]);

  const isDeployed = !!poolDetail?.is_deploy;
  const editorRef = useRef(null);
  const onChange = () => {
    if (editorRef.current) {
      setValue(fieldName, editorRef.current.getContent());
      setDescription(editorRef.current.getContent());
    }
  };
  const handleImageUpload = (blobInfo, progress, failure) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("access_token");
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/admin/pool/upload-file?id=`+poolDetail.id,
        true,
      );
      xhr.setRequestHeader("Authorization", "Bearer "+token);
      console.log(xhr, "xhrrrr");

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      //console.log(blobInfo.filename())

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
        if (progress && typeof progress === "function") {
          const percent = 0;
          progress(percent);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.data.Location != "string") {
          reject("Invalid JSON: " + xhr.responseText);
          return;
        }
        resolve(json.data.Location);
      };

      xhr.onerror = () => {
        reject({ message: "Image upload failed", remove: true });
        if (failure && typeof failure === "function") {
          failure("Image upload failed");
        }
      };

      xhr.send(formData);
    });
  };

  const timyInitConfig = {
    height: 500,
    menubar: true,
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "code",
      "help",
      "wordcount",
    ],
    toolbar:
      "undo redo | blocks | " +
      "bold italic forecolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    images_upload_url: `${BASE_URL}/admin/pool/upload-file`,
    automatic_uploads: true,
    images_reuse_filename: true,
    images_upload_handler: handleImageUpload,
  };

  return (
    <>
      <div className={classes.formCKEditor}>
        <label className={classes.formControlLabel}>{header}</label>
        <Controller
          control={control}
          rules={{
            required: false,
          }}
          name={fieldName}
          render={(field) => {
            return (
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                onInit={(evt: any, editor) => (editorRef.current = editor)}
                initialValue={poolDetail[fieldName]}
                init={timyInitConfig}
                onEditorChange={(evt: any, editor: any) => onChange()}
              />
            );
          }}
        />

        <p className={classes.formErrorMessage}>
          {renderError(errors, fieldName)}
        </p>
      </div>
    </>
  );
}

export default PoolDescription;
