import React from 'react';
import useStyles from "../style";
import {renderErrorCreatePool} from "../../../utils/validate";
import ImageUploader from './ImageUploader';

function TokenLogo(props: any) {
  const classes = useStyles();
  const {
    register, errors,
    poolDetail, setValue
  } = props;
  const renderError = renderErrorCreatePool;
  const handleFileSelect = (imageUrl: any) => {
    setValue("tokenImages", imageUrl); // Assuming you're using something like useForm from react-hook-form
  };

  return (
    <>
      <div className={classes.formControl} style={{ marginTop: 0 }}>
        <label className={classes.formControlLabel}>Token Icon</label>
        <input
          type="text"
          name="tokenImages"
          defaultValue={poolDetail?.token_images}
          ref={register({ required: true })}
          className={classes.formControlInput}
        />

        <p className={classes.formErrorMessage}>
          {renderError(errors, "tokenImages")}
        </p>
        <ImageUploader
          onFileSelect={handleFileSelect}
          width="50%"
          value={poolDetail?.token_images}
          poolId={poolDetail?.id}
        />
      </div>
    </>
  );
}

export default TokenLogo;
